import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import loadable from "@loadable/component"
// import Autosuggest from "react-autosuggest"
import AutosuggestHighlightMatch from "autosuggest-highlight/match"
import AutosuggestHighlightParse from "autosuggest-highlight/parse"
import { Link } from "react-router-dom"
import Geosuggest from "react-geosuggest"
import { t } from "../../../../config"
import Style from "./index.less"
import theme from "./theme.less"
import StylesSprite from "../../../assets/less/sprite.less"
import { openSearch } from "../../../redux/actions/ToursActions"
import { getRecentSearch } from "../../../redux/actions/LocalstorageAction"
import {
    getSuggestions,
    getPopular,
    setSuggestionValue,
    clearSuggestions,
    homeSearchToogle,
} from "../../../redux/actions/SearchAction"
import { debounce as _debounce } from "../../tool/util"
import { urlPrettify } from "../../tool/Formatter"

const Autosuggest = loadable(() => import("react-autosuggest"))
// const Geosuggest = loadable(() => import("react-geosuggest"))

function setIcon(type) {
    let itineraryIcon
    switch (type) {
    case "hotels":
        itineraryIcon = <div className={Style.suggestionIconDiv}><em className={`${StylesSprite.sprite_base} ${Style.hotel_icon}`} /></div>
        break
    case "multiday tours":
        itineraryIcon = <div className={Style.suggestionIconDiv}><em className={`${StylesSprite.sprite_base} ${Style.multiday_icon}`} /></div>
        break
    case "things to do":
        itineraryIcon = <div className={Style.suggestionIconDiv}><em className={`${StylesSprite.sprite_base} ${Style.things_to_do_icon}`} /></div>
        break
    case "transportation":
        itineraryIcon = <div className={Style.suggestionIconDiv}><em className={`${StylesSprite.sprite_base} ${Style.transport_icon}`} /></div>
        break
    default:
        itineraryIcon = <div className={Style.suggestionIconDiv}><em className={`${StylesSprite.sprite_base} ${Style.map_icon}`} /></div>
    }
    return itineraryIcon
}

function getFormatedType(type) {
    if (type) {
        return ", " + (
            type === "multiday tours"
                ? t("vacation packages")
                : t(type)
        )
    }
    return ""
}

function renderSuggestion(item, { query }) {
    const matches = AutosuggestHighlightMatch(item.name, query)
    const parts = AutosuggestHighlightParse(item.name, matches)

    return (
        <div className={Style.suggestionItem}>
            <span className={Style.suggestion_icon}>{setIcon(item.type)}</span>
            <span className={Style.sug_text}>
                {parts.map((part, index) => {
                    const className = part.highlight ? Style.suggestion_match : null

                    return (
                        <span className={className} key={index}>
                            {part.text}
                        </span>
                    )
                })}
            </span>
            {getFormatedType(item.type)}
        </div>
    )
}

class Search extends React.Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.debounced = _debounce(value => this.props.getSuggestions(value), 50)
        this.placeSelected = _debounce((userInput, isText = false) => {
            this.props.placeSelected(userInput, isText)
        }, 500)
        this.suggestElement = data => (
            <div className={Style.suggestionItem}>
                <span className={Style.suggestion_icon}>{setIcon("map")}</span>
                <span className={Style.sug_text}>
                    {data.label}
                </span>
            </div>
        )
        this.geoSuggest = {}
    }

    componentDidMount() {
        if (this.props.isNearBy) {
            this.geoSuggest.focus()
        } else {
            this.props.getRecentSearch()
            this.props.getPopular()
        }
    }

    onChange = (event, { newValue }) => {
        this.props.setSuggestionValue(newValue)
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.debounced(value)
    }

    onSuggestionsClearRequested = () => {
        this.props.clearSuggestions()
    }

    onSuggestionSelected = (event, { suggestion }) => {
        this.props.setSuggestionValue(suggestion.name)
        // const searchType = suggestion.type === "" ? "" : "/" + urlPrettify(suggestion.type)
        this.props.history.push(suggestion.url)
        // this.props.history.push(`/tours/${urlPrettify(suggestion.name) + searchType}`)
        this.props.openSearch(false)
    }

    getSuggestionValue = suggestion => suggestion.name

    setFocus = autosuggestObj => {
        if (autosuggestObj !== null) {
            autosuggestObj.input.setSelectionRange(
                autosuggestObj.input.value.length,
                autosuggestObj.input.value.length,
            )
            this.autosuggest = autosuggestObj.input
        }
    }

    clearSearchValue = () => {
        if (this.props.isNearBy) {
            this.geoSuggest.focus()
            this.geoSuggest.clear()
        } else {
            this.props.setSuggestionValue("")
            this.autosuggest.focus()
        }
    }

    handleSubmit = e => {
        e.preventDefault()
        this.props.history.push(`/tours/${urlPrettify(e.target.autosuggest.value)}`)
    }

    renderRecentSearch = () => {
        if (this.props.recentSearch.length) {
            return (
                <div className={Style.search_list}>
                    <h2>
                        {t("Recent Searches")}
                        :
                    </h2>
                    <div className={Style.search_tags}>
                        {
                            this.props.recentSearch.map((item, key) => (
                                <Link key={`recent_${key}`} to={`/tours/${urlPrettify(item)}`}>
                                    {
                                        (item === "Near Me") ? t("Near Me") : item
                                    }
                                </Link>
                            ))
                        }
                    </div>
                </div>
            )
        }
        return false
    }

    renderPopulerSearchh = () => {
        if (this.props.popularSearch.length) {
            return (
                <div className={Style.search_list}>
                    <h2>
                        {t("Popular Searches")}
                        :
                    </h2>
                    <div className={Style.search_tags}>
                        {
                            this.props.popularSearch.map((item, key) => (
                                <Link key={`recent_${key}`} to={`/tours/${urlPrettify(item)}`}>{item}</Link>
                            ))
                        }
                    </div>
                </div>
            )
        }
        return false
    }

    render() {
        const inputProps = {
            placeholder: t("Search"),
            name: "autosuggest",
            value: this.props.isNearBy ? this.props.placeString : this.props.searchValue,
            onChange: this.onChange,
            autoFocus: true,
            type: "search",
        }
        let btnClass = Style.search_section_module
        if (this.props.isFromHeader) btnClass += " " + Style.header_search_section_module
        const geoOnKeyPress = e => {
            const code = (e.keyCode ? e.keyCode : e.which)
            if (code === 13) {
                this.placeSelected(this.geoSuggest.state.userInput, true)
                this.props.openSearch(false)
            }
        }
        return (
            <section className={btnClass}>
                <div className={Style.container}>
                    <div className={Style.search_section_module_form}>
                        <form
                            action="#"
                            onSubmit={e => {
                                e.preventDefault()
                                if (!e.target.autosuggest.value) {
                                    return
                                }

                                if (this.props.isNearBy) {
                                    this.placeSelected(this.geoSuggest.state.userInput, true)
                                    this.props.openSearch(false)
                                    return
                                }
                                this.props.history.push(`/tours/${urlPrettify(e.target.autosuggest.value === "Cerca de Mí" ? "Near Me" : e.target.autosuggest.value)}`)
                            }}
                            data-test-id="form-search"
                        >
                            <div className={Style.searchAutosuggest}>
                                <button
                                    className={`${StylesSprite.sprite_base} ${Style.searchIcon}`}
                                    type="submit"
                                    data-test-id="btn-search-submit"
                                    aria-label="Search"
                                />
                                {
                                    this.props.isNearBy
                                        ? (
                                            <Geosuggest
                                                placeholder={inputProps.placeholder}
                                                ref={el => { this.geoSuggest = el }}
                                                onSuggestSelect={e1 => {
                                                    this.props.openSearch(false)
                                                    this.placeSelected(e1, false)
                                                }}
                                                renderSuggestItem={this.suggestElement}
                                                className="search_section_module_input"
                                                onKeyDown={geoOnKeyPress}
                                            />
                                        ) : (
                                            <Autosuggest
                                                theme={theme}
                                                ref={this.setFocus}
                                                className="search_section_module_input"
                                                suggestions={this.props.suggestions}
                                                onSuggestionsFetchRequested={
                                                    this.onSuggestionsFetchRequested
                                                }
                                                onSuggestionsClearRequested={
                                                    this.onSuggestionsClearRequested
                                                }
                                                getSuggestionValue={this.getSuggestionValue}
                                                renderSuggestion={renderSuggestion}
                                                inputProps={inputProps}
                                                onSuggestionSelected={this.onSuggestionSelected}
                                            />
                                        )
                                }
                                <a onClick={this.clearSearchValue}><img src="../../../public/img/search_module_close_icon.png" alt="" title="" /></a>
                            </div>
                        </form>
                        <input
                            type="button"
                            onClick={() => {
                                if (this.props.isFromHomePage) {
                                    this.props.homeSearchToogle(false)
                                }
                                if (this.props.isFromHeader) {
                                    this.props.cancelAction()
                                }
                                this.props.openSearch(false)
                            }}
                            value={t("Cancel")}
                            className={Style.search_section_module_button}
                        />
                    </div>
                    {
                        !this.props.isFromHeader
                        && !this.props.isNearBy
                        && (
                            this.renderRecentSearch()
                            || this.renderPopulerSearchh()
                        )
                    }
                </div>
            </section>
        )
    }
}
function mapStateToProps(state) {
    return {
        ...state.SearchReducer,
    }
}
Search.propTypes = {
    popularSearch: PropTypes.oneOfType([PropTypes.array]),
    searchValue: PropTypes.string,
    isFromHomePage: PropTypes.bool,
    isFromHeader: PropTypes.bool,
    openSearch: PropTypes.func.isRequired,
    recentSearch: PropTypes.oneOfType([PropTypes.array]),
    history: PropTypes.oneOfType([PropTypes.object]).isRequired,
    suggestions: PropTypes.oneOfType([PropTypes.array]),
}
Search.defaultProps = {
    searchValue: "",
    popularSearch: [],
    recentSearch: [],
    suggestions: [],
    isFromHomePage: false,
    isFromHeader: false,
}

export default connect(
    mapStateToProps,
    {
        getSuggestions,
        getPopular,
        getRecentSearch,
        setSuggestionValue,
        clearSuggestions,
        homeSearchToogle,
        openSearch,
    },
)(Search)
